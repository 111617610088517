.cart__page__whole__container {
    overflow-y: scroll;
    position: relative;
  }
  .cart__with__element {
    overflow-y: scroll;
    height: 100vh;
    margin-top: 80px;
  }
  .cart__with__no__element {
    color: var(--srtong__font__color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 93vh;
    gap: 20px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.3rem;
  }
  .cart__with__no__element button {
    background-color: var(--button__color);
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 7px;
  }
  .cart__with__no__element button:focus {
    outline: none;
    border: none;
  }
  
  .user__not__login {
    color: var(--less__strong__font__color);
    font-family: "Nunito Sans", sans-serif;
    display: flex;
    flex-direction: column;
  
    gap: 20px;
    margin: 10px;
    padding: 15px 0px;
    font-size: 1rem;
    background-color: var(--background__color__tab);
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  
  .user__not__login span {
    text-align: center;
    font-size: 1rem;
    font-family: "Nunito Sans", sans-serif;
  }
  
  .user__not__login div {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .user__not__login div button {
    padding: 5px 20px;
    background-color: var(--button__color);
    font-family: "Nunito Sans", sans-serif;
    border: none;
    outline: none;
    border-radius: 4px;
    color: var(--less__strong__font__color);
  }
  
  .user__not__login div button :focus {
    border: none;
    outline: none;
  }
  
  .user__header__name {
    font-size: 1.5rem;
    padding: 20px;
    color: var(--srtong__font__color);
  }
  
  .cart__container__whole__container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 15px;
    border-bottom: 7px solid var( --most_light_border);
    padding-bottom: 15px;
  }
  .cart__element__left__part {
    flex-basis: 20%;
  }
  .cart__element__left__part img {
    height: 80px;
    width: 80px;
    border-radius: 10px;
  }
  
  .cart__element__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
  
  .cart__element__right__part {
    display: flex;
    flex-direction: column;
  }
  .product__price {
    color: var(--srtong__font__color);
    font-size: 1.1rem;
  }
  
  .product__name__remove {
    display: flex;
    justify-content: space-between;
    width: 25vh;
    color: var(--srtong__font__color);
  }
  
  .price__part__element {
    color: var(--srtong__font__color);
  }
  .price_details_price_part {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: var(--light_black_color);
   
  }
  .cart__page__price__details {
    border-top: 10px solid var(--very__light__border);
  }
  .price_details_heading {
    padding: 5px 10px;
    color: var(--strong__black__color);
    font-size: 1.2rem;
    font-weight: 600;
  }
  .price__part__element {
    display: flex;
    justify-content: space-between;
    font-family: "Nunito Sans", sans-serif;
    font-size: 0.9rem;
    align-items: center;
  }
  .Place__order__button__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 100px;
  }
  .Place__order__button__container button {
    padding: 10px 50px;
    width: 90vw;
    background-color: var(--button__color);
    border: none;
    color: var(--srtong__font__color);
    border-radius: 7px;
    font-size: 1.1rem;
  }
  
  .Place__order__button__container button:focus {
    outline: none;
  }
  .Place__order__button__container button:disabled {
    opacity: 0.6;
    color: var(--less__strong__font__color);
  }
  .product__quantity__container {
    width: 30vw;
    display: flex;
    justify-content: end;
  }
  .react-datepicker__input-container input{
    margin-left: 5px !important;
    width: 100% !important;
    padding: 6px 12px 8px 38px !important;
  }
  .product__quantity__increment__button__container {
    display: flex;
    /* gap: 10px; */
    justify-content: space-between;
    background-color: var( --count_button_color);
    color: var(--srtong__font__color);
    font-family: "Nunito Sans", sans-serif;
    align-items: center;
    border-radius: 5px;
    width: 100%;
  }
  
  .product__quantity__increment__button__container button {
    border: none;
    background: transparent;
    color: var(--srtong__font__color);
  }
  .product__quantity__increment__button__container button:focus {
    border: none;
    outline: none;
  }

  .product_name_count_price{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .cart_element_left_part{
    width: 70vw;
    display: flex;
    justify-content: space-between;
 
  }
  .cart_product_name{
    flex-basis: 66vw;
    font-size: 1.2rem;
  }
  .cart_element_left_part{
    border-bottom: 2px dashed var( --most_light_border);
    padding-bottom: 10px;
  }
  .cart_product__price{
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  .cart_product_description{
    width: 70vw;
    font-size: 0.8rem;
    font-family: "Roboto Condensed", sans-serif;
    color: var(  --light__text__color);
  }
  .place__order__button__container{
        position: fixed;
        bottom: 0px;
        left: 0;
        z-index: 99999;
        background-color: white;
        height: 60px;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
  }
  .place__order__button__container button{
    width: 85vw;
    height: 5vh;
    border: none;
    font-size: 1.3rem;
    font-family: "Roboto Condensed", sans-serif;
    border-radius: 5px;
    background-color: var(--big_button_back_color);
    color: var(  --more__strong__text__color);
  }

  .modal_from_bottom_whole_container{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999999;
    background-color: rgb(236, 236, 236);
    height: auto;
    width: 100vw;
    padding: 20px 0px;
    font-family: "Roboto Condensed", sans-serif;
  }
  .modal_heading{
    width: 96%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(  --border__color);
  }
  .modal_heading span{
    flex-basis:90%;
    text-align: center;
    font-size: 1.3rem;

  }
  .address_details_input{
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 10px;
    font-size: 1.1rem;
  }
  .address_details_input input{
    height: 4.5vh;
    border: none;
    border-radius: 4px;
    padding: 0px 10px;
    font-size: 1.1rem;
  }
  .address_details_input input:focus{
    outline: none;
  }
  .address_details_modal{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    font-family: "Roboto Condensed", sans-serif;
  }


  .modal_button_whole_container{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .modal_button_whole_container button{
    width: 90%;
    padding: 8px;
    font-size: 1.2rem;
    border-radius: 5px;
    border: none;
    background-color: var(--big_button_back_color);
    color: var(  --more__strong__text__color);
    font-family: "Roboto Condensed", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal_time_slot{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    padding: 10px;
    column-gap: 70px;
    width: 90%;
  
  }
  .time_slot_element{
    background-color: var( --background-color);
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    border-radius: 5px;
  }
  .active_time_slot{
    background-color: var(--big_button_back_color);
    color: var(  --more__strong__text__color);
  }
  .radio_button_container{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
  }
  .radio_button_container input{
    height: 30px;
    width: 30px;
  }
  .radio_button_container lable{
    font-size: 1.3rem !important;
  }

  .cash_booking_confirm_modal{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999999;
    background-color: rgb(255, 255, 255);
  
    

  }
  .cash_booking_modal_heading{
    display: flex;
    border-bottom: 1px solid gray;
    padding: 15px;
  }
  .heading_name{
    width: 90%;
    font-size: 1.4rem;

  }
  .modal_cross_icon{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: end;
   
  }
  .verify_payment_loading{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    width: 100vw;
    height: 100vh;
    background-color: white;
  }

  .error__message{
    font-size: 0.9rem;
    color: red;
}
.payment_modal_button_container{
  gap: 20px !important;
}
.payment_modal_button_container button{
   width: 45vw !important;
   height: 5vh !important;
  
}
