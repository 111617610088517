.mobile__device__header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
}
.header__logo__container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.header__logo__container img{
  height: 30px;
  width: 30px;
}
.mobile__device__header__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--bar__color);
  font-family: "Roboto Condensed", sans-serif;
  height: 6.5vh;
  align-items: center;
  padding: 0px 10px;
}
.header__left__part{
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.3rem;
}

.header__right__part {
  display: flex;
  gap: 20px;
  align-items: center;
}
