.payment_success_whole_container{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99999999;
    background-color: #f8f8f8;
}
.payment_success_header{
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 15px;
    font-size: 1.3rem;
    border-bottom: 0.5px solid var( --light_black_color);
}
.payment_success_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    background-color: #f8f8f8;
    text-align: center;
  }
  
  .payment_success_message {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .payment_success_message h2 {
    color: #4CAF50;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .payment_success_message p {
    font-size: 18px;
    color: #333333;
  }
  
  .payment_success_message span {
    font-weight: bold;
    color: #4CAF50;
  }
  
  .view_order_button {
    background-color: #4CAF50;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .view_order_button:hover {
    background-color: #45a049;
  }
  