@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Parkinsans:wght@300..800&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Parkinsans", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* font-family: "Roboto Condensed", sans-serif; */
:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --font-size: 16px;
  --bar__color: rgb(238 237 237);
  --light__text__color:rgb(145, 143, 143);
  --strong__text__color:rgb(197, 196, 196);
  --more__strong__text__color:white;
  --register__login__background:rgb(46, 12, 78);
  --strong__black__color:black;
  --light_black_color:rgba(56, 55, 55, 0.843);
  --border__color:rgba(149, 149, 149, 0.476);
  --light_border_color:rgba(199, 199, 199, 0.387);
  --most_light_border:rgba(208, 207, 207, 0.258);
  --button_border_color:rgb(177, 92, 177);
  --button_text_color: rgb(93, 4, 93);
  --button_background:rgb(93, 4, 93);
  --count_button_color:rgba(222, 125, 243, 0.244);
  --big_button_back_color:blueviolet;
 --background-color: white;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

