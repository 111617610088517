.help-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
  /* Title */
  .help-container h1 {
    text-align: center;
    color: #333;
  }
  
  /* Form */
  .help-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Form Group */
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: "Roboto Condensed", sans-serif; 
  }
  
  /* Textarea */
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  /* Submit Button */
  .submit-button {
    padding: 10px 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .help-container {
      padding: 10px;
      margin-top: 70px;
      font-family: "Roboto Condensed", sans-serif; 
    }
  
    .help-form {
      gap: 10px;
    }
  
    .submit-button {
      font-size: 0.9rem;
    }
  }